<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
    @delete="onDeleteMultiple"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="cursor-pointer" @click="onClickUpdate(item.id)">
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          @{{ item.username }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-center">
        <VAction icon="edit" @click="onClickUpdate(item.id)" />

        <VAction
          v-if="isSysAdmin"
          icon="personify"
          @click="onClickPersonify(item.id)"
        />

        <VDeleteAction
          :item="item"
          :text-value="`${item.firstname} ${item.lastname}`"
          :delete-function="onClickDelete"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
import useUser from "@/composables/useUser";
import useRead from "@/composables/useRead";
import useRequest from "@/composables/useRequest";
// Components
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VDeleteAction,
    VList,
    VAction,
    VImage,
    VChip
  },
  setup() {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    // CONSTANTS
    const documentTitle = `${t("app.users", 2)} - ${t("app.administration")}`;

    // COMPUTED
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_name: `${row.firstname} ${row.lastname}`,
        displayed_status: row.status ? t(`app.${row.status}`) : "",
        displayed_avatar: getAvatarURL(row?.avatar?.view_path)
      }));
    });
    const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);

    // CUSTOM COMPOSABLES
    const { request } = useRequest();
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();
    const { endpoint, route, types, searchFields, headers } = useUser();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      getData
    } = useRead({
      endpoint,
      route,
      relations: ["avatar"],
      types,
      searchFields
    });

    // METHODS
    const onClickPersonify = async id => {
      const response = await request({
        endpoint: "administration.users.impersonate",
        pathParams: { id }
      });

      store.dispatch("user/setPersonification", response?.payload?.data);
      router.push({ name: "home" });
    };

    const onDeleteMultiple = async ids => {
      await request({
        endpoint: `${endpoint}.delete`,
        pathParams: {
          id: ids.toString()
        }
      });

      await getData();
    };

    return {
      isSysAdmin,
      onDeleteMultiple,
      displayedRows,
      documentTitle,
      onClickPersonify,
      // useUser
      headers,
      // useColor
      getStatusColor,
      // useRead
      onUpdateCurrentPage,
      onUpdatePerPage,
      pagination,
      onClickUpdate,
      isLoading,
      onClickDelete
    };
  }
};
</script>
